<template>
  <div>
    <provision-list></provision-list>
  </div>
</template>


<script>
import ProvisionList from './../../components/provisions/list';
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$i18n.t('provisions.title'),
    };
  },
  components: {
    ProvisionList
  }
}
</script>