<template>
  <div>
    <div class="font-weight-medium text-h6 mb-3">
      {{ $i18n.t("provisions.title") }}
    </div>
    <v-card class="rounded-md mb-4">
      <div class="d-flex align-center justify-start px-4 py-3 flex-wrap">
        <v-btn
          depressed
          @click="refreshItems()"
          :loading="loading.refresh"
          class="mr-2"
        >
          <v-icon left>mdi-refresh</v-icon>
          {{ $i18n.t("btn.refresh") }}
        </v-btn>
        <v-btn depressed @click="filterItems()" :loading="loading.filter">
          <v-icon left>mdi-filter-variant</v-icon>
          {{ $i18n.t("btn.filter") }}
        </v-btn>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="loading.list"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :footer-props="footerProps"
        :items-per-page="itemPerPage"
        sort-by="id"
        sort-desc
      >
        <template v-slot:body="{ items }" v-if="!_.isEmpty(items)">
          <tbody>
            <template v-for="item in items">
              <tr :key="item.id">
                <td>
                  <span>{{
                    $moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")
                  }}</span>
                </td>
                <td>{{ item.service.name }}</td>
                <td>
                  <v-chip
                    class="table-chips"
                    :color="item.operation === 'CREDIT' ? 'success' : 'danger'"
                    dark
                    >{{ item.operation }}</v-chip
                  >
                </td>
                <td style="min-width: 100px;">
                  <v-chip class="table-chips">{{ item.price }}</v-chip>
                </td>
                <td style="min-width: 130px;">
                  <v-chip class="table-chips">{{ item.old_balance }}</v-chip>
                </td>
                <td style="min-width: 150px;">
                  <v-chip class="table-chips">{{ item.new_balance }}</v-chip>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <provision-filter
      @filter="applyFilter"
      :configuration="filter.provision"
      :dialog="dialog.filter"
    ></provision-filter>
  </div>
</template>

<script>
import ProvisionFilter from "./../commons/filter";
import ListMixin from "./../../mixins/list";
import { mapActions } from 'vuex';

export default {
  mixins: [ListMixin],
  created() {
    this.setFilterProvision();
  },
  data: () => ({
    path: "provisions-services.list",
    filter: {
      provision: [],
    },
    item: {},
    filters: {},
  }),
  methods: {
    ...mapActions({ request: "request" }),
    setHeaders() {
      this.headers = [
        {
          text: this.$i18n.t("provisions.fields.created.title"),
          align: "start",
          sortable: true,
          value: "created_at",
          width: 160,
        },
        {
          text: this.$i18n.t("provisions.fields.service.title"),
          align: "start",
          sortable: false,
          value: "service.name",
        },
        {
          text: this.$i18n.t("provisions.fields.operation.title"),
          align: "start",
          sortable: true,
          value: "operation",
          width: 110,
        },
        {
          text: this.$i18n.t("provisions.fields.amount.title"),
          align: "start",
          sortable: false,
          value: "price",
          width: 100,
        },
        {
          text: this.$i18n.t("provisions.fields.old_credit.title"),
          align: "start",
          sortable: false,
          value: "old_balance",
          width: 130,
        },
        {
          text: this.$i18n.t("provisions.fields.new_credit.title"),
          align: "start",
          sortable: false,
          value: "new_balance",
          width: 150,
        },
      ];
    },
    setFilterProvision() {
      const self = this;
      this.filter.provision = [
        {
          name: "service.id",
          type: {
            name: "VAutocomplete",
            operator: "$eq",
            options: {
              label: this.$i18n.t("provisions.fields.service.title"),
              placeholder: this.$i18n.t(
                "provisions.fields.service.placeholder"
              ),
              clearable: true,
              loading: false,
              items: [],
              "cache-items": true,
              "item-value": "id",
              "item-text": "name"
            },
            events: {
              async "keydown"(e) {
                const val = e.target.value;
                if (!val) return;
                if (val.length < 2) return;
                self.filter.provision[0].type.options.items = (
                  await self.request({
                    url: `services.list?filter=name||$contL||${val}`,
                  })
                ).data.data;
              },
            },
          },
        },
      ];
    },
    filterItems() {
      this.dialog.filter.display = true;
      this.dialog.filter.title = this.$i18n.t("provisions.filter.title");
    },
  },
  components: {
    ProvisionFilter,
  },
};
</script>
